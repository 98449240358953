import { ElLoading } from 'element-plus'
import axios from 'axios'
let loading
//创建实例
const $http = axios.create({
  baseURL: 'https://rhkpwy.com/',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  timeout: 7000,
  transformRequest: [function (data) {
    let ret = ''
    for (let it in data) {
      ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
    }
    return ret;
  }]
});


// 添加请求拦截器
$http.interceptors.request.use(function (config) {
  loading = ElLoading.service({
    text: '加载中',
    spinner: 'el-icon-loading',
    background: 'rgba(0,0,0,0)'
  })
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  loading.close()
  return Promise.reject(error);
});

// 添加响应拦截器
$http.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  loading.close()
  return response;
}, function (error) {
  // 对响应错误做点什么
  loading.close()
  return Promise.reject(error);
});

export default $http